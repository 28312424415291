import $ from "jquery";
import "devbridge-autocomplete";

/*
 * Original: EXT:solr/Resources/Public/JavaScript/suggest_controller.js
 * Customized for use with heidenhain.de
 */

function SuggestController() {
  this.init = () => {
    $("form[data-suggest]").each((idx, item) => {
      const $form = $(item);

      let $searchBox = $form.find("input");

      $form.find(".tx-solr-suggest-focus").focus();

      if ($searchBox.length === 0) {
        $searchBox = $form;
      }
      $searchBox.css("position", "relative");

      $form.on("submit", (e) => {
        if ($searchBox.val() === "") {
          e.preventDefault();
          $searchBox.focus();
        }
      });

      $searchBox
        .devbridgeAutocomplete({
          serviceUrl: $form.data("suggest"),
          dataType: "jsonp",
          ajaxSettings: {
            jsonp: "tx_solr[callback]",
          },
          paramName: "tx_solr[queryString]",
          groupBy: "category",
          maxHeight: 1000,
          appendTo: $form,
          autoSelectFirst: false,
          triggerSelectOnValidInput: false,
          onSelect: (suggestion) => {
            if (suggestion.data.link) {
              window.location.href = suggestion.data.link;
            } else {
              $form.trigger("submit");
            }
          },
          transformResult: (response) => {
            if (!response.suggestions) return { suggestions: [] };
            let firstSuggestion;
            const result = {
              suggestions: $.map(response.suggestions, (count, suggestion) => {
                if (!firstSuggestion) firstSuggestion = suggestion;
                return {
                  value: suggestion,
                  data: {
                    category: "suggestion",
                    count,
                  },
                };
              }),
            };

            $.each(response.documents, (key, value) => {
              const dataObject = value;

              dataObject.category = $form.data("suggest-header")
                ? $form.data("suggest-header")
                : "Top results";

              if (dataObject.group) {
                const dataKey = `suggest-header-${dataObject.group}`;
                dataObject.category = $form.data(dataKey)
                  ? $form.data(dataKey)
                  : dataObject.group;
              }

              result.suggestions.push({
                value: firstSuggestion,
                data: dataObject,
              });
            });

            result.suggestions.push({
              value: firstSuggestion,
              data: {
                category: "result",
              },
            });

            return result;
          },
          beforeRender: (container) => {
            container.find(".autocomplete-group:first").remove();
            container.addClass("tx-solr-autosuggest");
            container.css("width", "");
            $searchBox.parent().addClass("autocomplete-active").fadeIn();
          },
          formatGroup: (suggestion, category) => {
            if (category === "result") {
              return "";
            }

            return `<div class="autocomplete-group">${category}</div>`;
          },
          formatResult: (suggestion, currentValue) => {
            if (!currentValue) {
              return suggestion.value;
            }
            const pattern = `(${$.Autocomplete.utils.escapeRegExChars(
              currentValue.trim()
            )})`;
            if (suggestion.data.category === "suggestion") {
              return this.formatTitle(suggestion.value, pattern, "strong");
            }
            if (suggestion.data.category === "result") {
              return `<div class="mt-3"><strong>${$form.data(
                "suggest-link"
              )}<svg xmlns="http://www.w3.org/2000/svg" width="8.414" height="14.836" viewBox="0 0 8.414 14.836" class="ml-2"><path class="icon icon__chevron icon--small icon--primary icon--thin" d="M0,6,6.007,0s5.99,5.987,6,6" transform="translate(7.414 1.414) rotate(90)"></path></svg></strong></div>`;
            }

            const title = this.formatTitle(
              suggestion.data.title,
              pattern,
              "em"
            );
            const previewImage = suggestion.data.previewImage
              ? `<figure ${
                  suggestion.data.hasVideo ? 'class="hasVideo"' : ""
                }><img src="${suggestion.data.previewImage}" /></figure>`
              : "";

            return `<div class="${suggestion.data.type}">${previewImage}<a href="${suggestion.data.link}" class="internal-link">${title}</a></div>`;
          },
        })
        .on("blur", (e) => {
          $searchBox.parent().removeClass("autocomplete-active");
          const $box = $(e.currentTarget);
          setTimeout(() => {
            $box.devbridgeAutocomplete("hide");
          }, 200);
        });
    });
  };

  this.formatTitle = (title, pattern, tag) =>
    title
      .replace(new RegExp(pattern, "gi"), `<${tag}>$1</${tag}>`)
      .replace(/&/g, "&amp;")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;")
      .replace(/"/g, "&quot;")
      .replace(new RegExp(`&lt;(/?${tag})&gt;`, "g"), "<$1>");
}

(function () {
  const solrSuggestController = new SuggestController();
  solrSuggestController.init();

  $("body").on("tx_solr_updated", () => {
    solrSuggestController.init();
  });
})();
