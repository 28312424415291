/**
 * Applies the following css classes to all navigation bars that have the .hero-nav set:
 *
 * - `navbar--style-transparent` if the viewport is on its top position.
 * - `navbar--style-dark` if the viewport isn't on the top anymore.
 */
const updateNavigationBarBackgrounds = () => {
  for (const navigationBar of document.querySelectorAll(
    ".js-navbar.hero-nav"
  )) {
    const classList = navigationBar.classList;
    const verticalDistanceFromDocumentTop =
      navigationBar.getBoundingClientRect().top + window.scrollY;

    if (verticalDistanceFromDocumentTop === 0) {
      classList.add("navbar--style-transparent");
      classList.remove("navbar--style-dark");
    } else {
      classList.remove("navbar--style-transparent");
      classList.add("navbar--style-dark");
    }
  }
};

updateNavigationBarBackgrounds();
document.addEventListener("scroll", updateNavigationBarBackgrounds);
