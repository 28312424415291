import $ from "jquery";

$(document).ready(() => {
  $(".js-language-select").click(function (e) {
    e.preventDefault();
    const languageId = $(this).data("language-id");
    const languageName = $(this).data("language-name");
    document.cookie = `i18n=${languageId}|${languageName}; expires=${new Date(
      new Date().getTime() + 1000 * 60 * 60 * 24 * 365
    ).toGMTString()}; path=/`;
    document.location.href = $(this).attr("href");
  });
});
