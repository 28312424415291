import { onDomReady } from "../../utils/on-dom-ready";

/**
 * Attach the actual computed height of the header to `:root`.
 */
const attachComputedHeaderHeight: ResizeObserverCallback = (resizeEntries) => {
  for (const resize of resizeEntries) {
    const headerHeight = resize.contentRect.height;
    document.documentElement.style.setProperty(
      "--actual-computed-header-height",
      `${headerHeight}px`
    );
  }
};

onDomReady(() => {
  const header = document.querySelector(".header");
  if (!header) {
    return;
  }

  const resizeObserver = new ResizeObserver(attachComputedHeaderHeight);
  resizeObserver.observe(header);
});
