import $ from "jquery";
import debounce from "lodash/debounce";

function isInViewport(elem) {
  const elementTop = $(elem).offset().top;
  const elementBottom = elementTop + $(elem).outerHeight();

  const viewportTop = $(window).scrollTop();
  const viewportBottom = viewportTop + $(window).height();

  return elementBottom > viewportTop && elementTop < viewportBottom;
}

function checkInternetExplorer() {
  if (window.document.documentMode) {
    return true;
  }
  return false;
}

function animateIfInViewport() {
  const isInternetExplorer = checkInternetExplorer();

  $(".js-on-visible").each(function () {
    if (isInViewport($(this)) && !isInternetExplorer) {
      $(this).find(".js-animate-on-visible").addClass("animate-in-view");
    } else if (isInternetExplorer) {
      $(this).addClass("d-none");
    }
  });
}

$(document).ready(animateIfInViewport);

$(window).on("resize scroll", debounce(animateIfInViewport, 100));
