import $ from "jquery";

function replaceNbsps(str) {
  const re = /&amp;nbsp;/g;
  if (re.test(str)) {
    return str.replace(re, "&nbsp;");
  }
  return str;
}

$(document).ready(() => {
  const els = document.getElementsByClassName("js-html-tag");
  Array.prototype.forEach.call(els, (el) => {
    let i = 0;
    const element = el;
    for (i; i < element.childNodes.length; i += 1) {
      const elChild = element.childNodes[i].innerHTML;
      element.childNodes[i].innerHTML = replaceNbsps(elChild);
    }
  });
});

$(document).ready(() => {
  const header = document.getElementsByClassName("js-html-tag-header");
  Array.from(header).forEach((el) => {
    const element = el;
    const textHeader = element.textContent;
    element.textContent = replaceNbsps(textHeader);
  });
});

$(document).ready(() => {
  const header = document.getElementsByClassName("js-html-tag-headline-card");
  Array.from(header).forEach((el) => {
    const element = el;
    const textHeaderCard = element.innerHTML;
    element.innerHTML = replaceNbsps(textHeaderCard);
  });
});
